*, ::after, ::before {
  box-sizing: border-box;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.footer {
  border-top: 1px solid rgba(72, 94, 144, .16);
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: .3px;
  padding: 15px 25px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

@media (min-width: 992px) {
  .footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.footer>div:last-child {
  margin-top: 15px;
}

@media (min-width: 576px) {
  .footer>div:last-child {
    margin-top: 0;
  }
}

.footer {
  background-color: #000 !important;
  color: #fff !important;
  width: 100%; 
}

.inner-header-admin {
  font-size: xx-large;
}

.data-grid-x {
  color: aqua!important;
}

.app_main {
  background-color: #f5f6fa!important ;
}

.inner-header-admin img {
  /* width: 150px; */
  height: 24px;
  padding: auto;
}