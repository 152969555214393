@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	/* content: url(../src/assets/svg/checked.svg);  */
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

/* --------- */

#dgfilter_icon, #dgfilter_filters {
    display: none;
    transition: 0.2s;
	
}
#dgfilter_filters {
	transition: 0.4s;
	z-index: 10;
	@apply  bg-gray-50;
}
/* className='cursor-pointer text-center absolute top-4 -left-3 font-bold w-3 h-3  mr-1' */
#dgfilter_icon{
	@apply cursor-pointer text-gray-500 dark:text-gray-500 p-2 flex rounded-[2px] left-0  bg-white dark:bg-navy-700;
}


#dgfilter_icon:hover{
	@apply  text-navy-700 dark:text-white;
	transition: 0.4s;
}

#dgfilter_icon:hover #dgfilter_filters {
    display:block;
}

.view-content {
	@apply relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none !p-[20px] text-center
}


.dx-state-hover {
    @apply bg-gray-50 text-navy-700 dark:text-gray-50 
}

/* nav tree on the side */
.tree-view {
	@apply bg-white text-navy-700 dark:!bg-navy-800 dark:text-white 
}

/* the indicator icon for tree item */
.dx-treeview-toggle-item-visibility::before {
	@apply absolute cursor-pointer text-center w-6 h-8 top-0 -left-1 text-navy-700 dark:!bg-navy-800 dark:text-white ;

}

.dx-datagrid {
    @apply bg-white text-navy-700 dark:!bg-navy-800 dark:text-white 
}

.dx-toolbar-items-container {
	/* @apply bg-white text-navy-700 dark:!bg-navy-800 dark:text-white  */
}

.dx-datagrid-header-panel .dx-toolbar {
	/* margin-bottom: 10px; */
	@apply dark:bg-navy-800 mb-[10px];
  }

.dx-popup-content {
	overflow-y: scroll !important;
	position: relative;
}


.dx-popup-content::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
	padding: 2px;
	margin: 2px;
}

.dx-popup-content::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: black;
}

.dx-treeview-item {
	cursor: unset!important;
}

.dx-treeview-node-is-leaf {
	cursor: pointer!important;
}
