body a {
    text-decoration: none;
    color: unset!important;
}

.gsma-header .inner-header {
    max-width: 75%;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 2;
    height: 88px;
    align-items: center;
}



.gsma-header .logo img {
    width: 100%;
}

.gsma-header {
    background-color: #DC002B;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: background 750ms ease-out;
}

.gsma-red-top-bar {
    height: 10px;
    background-color: #dc002b;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1;
}

.gsma-logo-header {
    background-color: #FFF;
    top: 10px;
    position: fixed;
}

.gsma-header .logo {
    width: 10%;
    left: 40px;
    height: 40px;
    position: fixed;
    color: red;
}

.gsma-header .main-nav {
    align-content: center;
    font-size: xx-large;
    margin-top: -20px;
    margin-left: 20px;
    height: 40px;
}

.gsma-red-bottom-bar {
    height: 10px;
    background-color: #dc002b;
    width: 100vw;
    bottom: 40px;
    left: 0;
    position: fixed;
    z-index: 10000;
}

.gsma-logo-footer {
    background-color: #000000;
    bottom: 0px;
    height: 40px;
    position: fixed;
    width: 100vw!important;
    color: #FFF;
    font-size: 12px;
    align-content: center;
    padding: 5px;
    z-index: 10000;
    left: 0;
}
